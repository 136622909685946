import styled from '@emotion/styled';

import { Badge } from '@/components/common/Badge';
import { screen } from '@/components/common/breakpoints';
import { BulletPoints } from '@/components/common/bulletpoints';
import { Button } from '@/components/common/Buttons';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import {
  Modal,
  ModalClose,
  ModalContent,
  ModalHeading,
  ModalTrigger,
} from '@/components/common/Modal';
import { TooltipButtonTrigger } from '@/components/common/TooltipButtonTrigger';
import { PortableText, VisuallyHidden } from '@/components/common/utils';
import { WistiaEmbed } from '@/components/common/WistiaEmbed';
import {
  getBulletPointColour,
  getButtonComponentThemeSolid,
} from '@/lib/colourUtils';
import { blockUrlWithAudience } from '@/lib/utils';
import { subheadingSmall } from '@/styles/typography';

import type { AlignmentOptions } from '@/components/common/CaptionHeadingDescription';
import type { TypographySizeTypes } from '@/styles/typography';
import type { BackgroundColourTokenType } from '@/types/colours';
import type { SanityAudienceType, SanityHeroContentType } from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface HeroContentProps
  extends Omit<SanityHeroContentType, 'cta'>,
    BlockWithAudience {
  cta?: Omit<SanityHeroContentType['cta'], 'audienceRef'> & {
    audienceRef?: SanityAudienceType;
  };
  headingSize?: TypographySizeTypes;
  backgroundColour?: BackgroundColourTokenType;
  contentAlignment?: 'center' | 'left';
}

export const HeroContent = ({
  tag,
  headingSize = 'display-lg',
  caption,
  heading,
  subheading,
  body,
  cta,
  tooltipButton,
  textColour,
  wistiaPopover,
  bulletPointStyle = 'check',
  backgroundColour,
  contentAlignment = 'left',
  pageAudience,
  isAudienceSwitcherEnabled,
}: HeroContentProps) => {
  const bulletPointColourToken =
    getBulletPointColour(backgroundColour) ?? textColour?.token;

  return (
    <InnerWrapper
      contentAlignment={contentAlignment}
      textColour={textColour.token}
    >
      <TextWrapper>
        {tag?.tagText && (
          <Badge
            bgColour={tag?.tagColour}
            margin="0 0 var(--spacing-xxx-small) 0"
          >
            {tag.tagText}
          </Badge>
        )}
        <CaptionHeadingDescription
          caption={caption}
          heading={heading}
          description={subheading}
          defaultHeadingSize={headingSize}
          padding={{
            description: 'large',
            descriptionMobile: 'large',
            captionMobile: 'xx-small',
            caption: 'xx-small',
          }}
          mobileAlignment={contentAlignment as AlignmentOptions}
          desktopAlignment={contentAlignment as AlignmentOptions}
          backgroundColourToken={backgroundColour}
          heroTextColourToken={textColour.token}
        />

        {body && (
          <Body
            className="bullet-points-paragraph"
            pointColour={bulletPointColourToken}
            bulletPointStyle={bulletPointStyle}
            paragraphFontSize="md"
          >
            <PortableText
              value={body}
              audienceConfig={{
                pageAudience: pageAudience,
                isAudienceSwitcherEnabled: isAudienceSwitcherEnabled,
              }}
            />
          </Body>
        )}
      </TextWrapper>
      <CtasContainer contentAlignment={contentAlignment}>
        {cta?.buttonLink && cta?.buttonText && (
          <CtaButtonWrapper>
            <CtaButton
              iconPosition="right"
              href={blockUrlWithAudience(
                cta.buttonLink,
                cta.audienceRef,
                pageAudience,
                cta.noAudienceToggle,
                isAudienceSwitcherEnabled,
              )}
              aria-label={cta.buttonAccessibleLabel}
              variant={getButtonComponentThemeSolid(backgroundColour)}
              className={
                cta.trackingTag?.blockName &&
                `tracking-${cta.trackingTag.blockName}__${cta.trackingTag.blockAction}`
              }
            >
              {cta.buttonText}
            </CtaButton>
          </CtaButtonWrapper>
        )}
        {wistiaPopover?.clickableText && (
          <Modal>
            <ModalTrigger>
              <Button
                variant="solid-light"
                icon="Play"
                className={
                  wistiaPopover.trackingTag?.blockName &&
                  `tracking-${wistiaPopover.trackingTag.blockName}__${wistiaPopover.trackingTag.blockAction}`
                }
              >
                {wistiaPopover.clickableText}
              </Button>
            </ModalTrigger>
            <ModalContent className="Modal">
              <ModalClose />

              <ModalHeading>
                <VisuallyHidden>{wistiaPopover.clickableText}</VisuallyHidden>
              </ModalHeading>
              <WistiaEmbed videoId={wistiaPopover.videoId} />
            </ModalContent>
          </Modal>
        )}
        {!!tooltipButton && tooltipButton.buttonText && (
          <TooltipButtonTrigger
            textColour={textColour?.token}
            {...tooltipButton}
          />
        )}
      </CtasContainer>
    </InnerWrapper>
  );
};

const Body = styled(BulletPoints)`
  margin-bottom: var(--spacing-large);
`;

const InnerWrapper = styled.div<
  Pick<HeroContentProps, 'textColour' | 'contentAlignment'>
>`
  color: ${({ textColour }) => `var(${textColour})`};
  display: flex;
  flex-direction: column;
  align-items: ${({ contentAlignment }) =>
    contentAlignment === 'center' ? 'center' : 'left'};
  text-align: ${({ contentAlignment }) => `${contentAlignment}`};

  ul {
    ${({ contentAlignment }) =>
      contentAlignment === 'center' && {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
      }};
  }

  ${screen.md} {
    margin-bottom: 0;
  }
`;

const TextWrapper = styled.div`
  ul {
    margin-top: var(--spacing-small);
  }

  .bullet-points-paragraph > p {
    ${subheadingSmall}
    margin-top: var(--spacing-xx-small);
    margin-bottom: var(--spacing-small);
  }
`;

const CtasContainer = styled.div<Pick<HeroContentProps, 'contentAlignment'>>`
  width: fit-content;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);

  align-items: ${({ contentAlignment }) =>
    contentAlignment === 'center' ? 'center' : 'flex-start'};

  &:has(> *) {
    margin-top: var(--spacing-large);
  }
`;

const CtaButtonWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const CtaButton = styled(Button)``;
